import React from 'react'
import { Link } from 'gatsby'
import { RiArrowLeftSLine } from '@react-icons/all-files/ri/RiArrowLeftSLine'
import { RiCheckboxCircleLine } from '@react-icons/all-files/ri/RiCheckboxCircleLine'
import Layout from '../components/layout'

const Privacy = () => (
  <Layout>
    <div
      className="wrapper"
      style={{
        textAlign: 'center',
      }}
    >
      <RiCheckboxCircleLine
        style={{
          fontSize: '128px',
          color: 'gray',
        }}
      />
      <h1>Privacy Policy</h1>
      <p>
        R.IDEA.LIZE,
        LLC(以下「弊社」)は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
      </p>
      <p>
        <h2>個人情報の管理</h2>

        <p>
          弊社は、利用者の個人情報について、漏洩、流用、改ざん、紛失等の防止に適切な対策を行います。
        </p>

        <p>
          また、弊社が運営するウェブサイトを通じてお客様に個人情報を提供していただく場合は、適切な安全性の確保に努めます。
        </p>

        <h2>情報収集の目的と範囲</h2>

        <p>
          個人情報を収集させていただく場合は、収集目的を通知したうえで、必要な範囲の個人情報を収集させていただき、決して他の目的に使用することはございません。
        </p>

        <h2>第三者への情報の開示・提供の禁止</h2>

        <p>
          お客様より収集させていただいた個人情報を適切に管理し、次のいずれかに該当する場合を除き、如何なる情報も第三者に開示・提供いたしません。
        </p>

        <p>
          利用者の同意がある場合
          <br />
          利用者のご希望に添うサービスを行うため、弊社が業務委託先・提携先に必要と認められる範囲で開示する場合
          <br />
          法令により開示が求められた場合
        </p>
      </p>
      <Link to="/" className="button">
        <RiArrowLeftSLine
          className="button-icon"
          style={{
            fontSize: '1.5rem',
            color: 'gray',
          }}
        />
        Back to Homepage
      </Link>
    </div>
  </Layout>
)

export default Privacy
